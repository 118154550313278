<template>
  <div class="row h-100 login-wrap">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="true"></loading>
    <div class="hidden-md col-md-5 right-login">
      <div class="wrap-content">
        <div class="subtitle-welcome">
          <img src="/static/images/login/titulo.png" alt="">
          {{ $t('login.content.subtitle') }}
        </div>
        <div class="content-welcome">
          <p>
            {{ $t('login.content.first') }}
            {{ $t('login.content.hightlight') }}
            {{ $t('login.content.last') }}
          </p>
          <a target="_blank" href="https://royalcargo.com.br/blog/2021/06/14/portal-web-da-royal-cargo-informacao-em-um-unico-lugar/">
            {{ $t('login.content.link') }} >>
          </a>
        </div>
      </div>
    </div>
    <div class="col-md-7 left-login">
      <div class="auth-content col-md-11 h-100 w-100">
        <div class="card-body">
          <div class="fast-access">
            <router-link :to="{name: 'Login'}">
              ACESSO eRoyal
            </router-link>
          </div>
          <div class="card-title text-center mb-5 font-18">
            <div class="text-center subtitle">
              <img src="/static/images/login/logo.png" alt="logo" class="logo">
              <span>{{ $t('login.title1') }}</span> {{ $t('login.title2') }}
            </div>
          </div>
          <div class="py-5 mt-5">
            <div class="text-center label">
              <h5 class="mb-4">Ops! Algo deu errado...</h5>
              <p class="pt-3">Sua solicitação de acesso não foi concluída. Se você já fez essa requisição antes, não é necessário fazer novamente.</p>
              <p>Agora basta aguardar nossa equipe avaliar a liberação do seu acesso.</p>
              <p>Você vai receber um e-mail quando sua solicitação for aceita.</p>
              <p>Você já recebeu o e-mail de aprovação? Então <router-link :to="{name: 'Login'}">{{ $t('login.click-here') }}</router-link> para fazer o login na sua conta.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import './login.css'
import '@/assets/css/custom.css'

export default {
  name: 'AccessRequestAborted',
  metaInfo: {
    titleTemplate: '%s | Solicitação recusada'
  },
  components: {
    Loading
  },
  data () {
    return {
      isLoading: false,
      loading: false
    }
  },
  methods: {
  }
}
</script>

<style scoped>
  .fast-access {
    position: absolute;
    right: 0;
    top: 10px;
    display: inline-block;
  }

  .fast-access a {
    color: #8c6d38 !important;
    padding: 10px;
    font-weight: normal;
  }

  .login-wrap .left-login form span {
    font-size: 0.95em;
  }

  .login-wrap .left-login .subtitle-content {
   max-width: 450px;
   margin: auto;
  }

  .content-welcome a {
    background-color: inherit;
    color: #d1b178 !important;
    padding-left: 0;
  }

  .content-welcome a:hover {
    color: #8c6d38 !important;
  }

  .auth-content a {
    color: #d1b178 !important;
  }

  .auth-content a:hover {
    color: #8c6d38 !important;
  }
</style>
